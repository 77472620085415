// ================== !!!!!!!!!!!!!!!! ==================
// Цей проект було написано давно, і він вже був переписаний. На той час, коли його переписували,
// все було нормально, але зараз він застарів, має багато місць для оптимізації, і залишилися старі баги.

// Це була моя власна ініціатива поступово переписувати код, оскільки у мене була інша основна робота і мало часу на це.
// Сподіваюся, тебе це не заплутає, і ти розберешся з цим. Якщо виникнуть будь-які питання, можеш звертатися за адресою qwertyqaz181207@gmail.com.
// ================== !!!!!!!!!!!!!!!! ==================

import axios from "axios";
import { store } from "../redux/store";

//db
import {
	mainLanguageData,
	withoutSlugLanguageData,
} from "../db/generals/languageData";
import { changeLangUkonUa } from "../utils/language/changeLangUkonUa";

// export const baseURL = "https://backtest.shopping.ua/api/";
// export const baseURLSingle = "https://backtest.shopping.ua/";
export const baseURL = "https://b.shopping.ua/api/"; // production api new
export const baseURLSingle = "https://b.shopping.ua/"; // production api new

const tokenShopping = localStorage.getItem("token_shopping");

export const combineURLs = (baseURL: string, relativeURL: string) => {
	return relativeURL
		? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
		: baseURL;
};

type TGetRequestProps = {
	url: string;
	success?: Function;
	setError?: Function;
	params?: object;
	headers?: object;
	obj?: any;
};

type TAxiosGetRequestProps = {
	params?: object;
	headers?: object;
	data?: object;
};

const getLocale = () => store.getState().app?.language || mainLanguageData.key;

export const getRequest = ({
	url,
	success,
	setError,
	params,
	headers,
}: TGetRequestProps) => {
	let objData: any = {
		headers: {
			Authorization: tokenShopping
				? "Bearer " + JSON.parse(tokenShopping)
				: "",
			Locale: getLocale(),
			...headers,
		},
	};

	if (params) {
		objData.params = {
			...params,
		};
	}

	// if (data) {
	//   objData = {
	//     ...objData,
	//     data,
	//   };
	// }
	axios
		.get<TAxiosGetRequestProps>(`${combineURLs(baseURL, url)}`, {
			...objData,
		})
		.then((res: any) => success && success(res.data))
		.catch((err: any) => {
			setError && setError(err);
		});
};

export const deleteRequest = ({
	url,
	obj,
	success,
	setError,
	headers,
	params,
}: any) => {
	let objData: any = {
		headers: {
			Authorization: tokenShopping
				? "Bearer " + JSON.parse(tokenShopping)
				: "",
			Locale: getLocale(),
		},
	};

	if (params) {
		objData.params = {
			params,
		};
	}
	if (obj) {
		objData.data = {
			obj,
		};
	}

	return axios
		.delete(`${combineURLs(baseURL, url)}`, { ...objData })
		.then((res) => {
			// console.log(res.data);
			success && success(res.data);
		})
		.catch((err) => {
			setError && setError(err);
		});
};

type TPostRequestProps = {
	url: string;
	success?: Function;
	setError?: Function;
	params?: object;
	headers?: any;
	data?: object;
	obj?: any;
};

export const postRequest = ({
	url,
	obj,
	success,
	setError,
	params,
	headers,
}: TPostRequestProps) => {
	let objData: any = {
		headers: {
			Authorization: tokenShopping
				? "Bearer " + JSON.parse(tokenShopping)
				: "",
			Locale: getLocale(),
			...headers,
		},
	};

	if (params) {
		objData.params = {
			...params,
		};
	}

	axios
		.post(`${combineURLs(baseURL, url)}`, obj, {
			...objData,
		})
		.then((res) => {
			// console.log(res.data);
			success && success(res.data);
		})
		.catch((err) => {
			setError && setError(err);
		});
};

export const putRequest = ({
	url,
	obj,
	success,
	setError,
	params,
}: TPostRequestProps) => {
	let objData: any = {
		headers: {
			Authorization: tokenShopping
				? "Bearer " + JSON.parse(tokenShopping)
				: "",
			Locale: getLocale(),
		},
	};

	if (params) {
		objData.params = {
			params,
		};
	}

	axios
		.put(`${combineURLs(baseURL, url)}`, obj, {
			...objData,
		})
		.then((res) => {
			success && success(res.data);
		})
		.catch((err) => {
			setError && setError(err);
		});
};

//NP

type TConfig = {
	modelName: any;
	calledMethod: any;
	methodProperties: any;
};

export const postNP = (
	url: any,
	modelName: any,
	calledMethod: any,
	Language: any,
	CityName: any,
	Limit: any,
	Page: any,
) => {
	const config: TConfig = {
		modelName,
		calledMethod,
		methodProperties: {},
	};

	if (Language) {
		config!.methodProperties!.Language = Language;
	}

	if (CityName) {
		config.methodProperties.CityName = CityName;
	}

	if (Limit) {
		config.methodProperties.Limit = Limit;
	}

	if (Page) {
		config.methodProperties.Page = Page;
	}

	return axios.post(`https://api.novaposhta.ua/v2.0/json/${url}`, config);
};
